<template>
  <amplify-authenticator
    id="amplify"
    username-alias="email"
    :class="
      $store.state.user && Object.keys($store.state.user).length !== 0
        ? 'hydrated'
        : 'add_bg hydrated'
    "
    v-bind:initial-auth-state="initial_auth_state"
  >
    <amplify-sign-up
      slot="sign-up"
      username-alias="email"
      :form-fields.prop="formFields"
    ></amplify-sign-up>
    <amplify-sign-in slot="sign-in" header-text="" hide-sign-up="true">
      <div slot="header-subtitle" class="text-light kallisto-bold text-center">
        <img
          :src="getIcon('Black_DSL_Logo_Horizontal.svg')"
          alt="dsl logo"
          class="black-logo d-none d-md-block"
        />
        <img
          :src="getIcon('Black_DSL_Logo_Horizontal_Mobile.svg')"
          alt="dsl logo"
          class="black-logo d-md-none d-block"
        />
        <h1 class="font-26 font-md-32">Welcome!</h1>
        <p class="font-14 font-md-18">Sign in to the Dreamscape Learn Portal</p>
      </div>
      <div slot="federated-buttons" class="federated-buttons">
        <p>
          <button class="px-3 py-2" v-show="sso_enabled" v-on:click="SSO">
            Login with ASURITE
          </button>
        </p>
      </div>
    </amplify-sign-in>
    <div>
      <div class="portal">
        <LoggedInUser />
      </div>
    </div>
  </amplify-authenticator>
</template>
<script>
// @ is an alias to /src
import LoggedInUser from "@/components/LoggedIn.vue";
import { AuthState } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { mapGetters } from "vuex";

export default {
  name: "Portal",
  components: {
    LoggedInUser,
  },
  data() {
    return {
      formFields: [
        {
          type: "email",
          label: "Email",
          placeholder: "Email",
          required: true,
        },
        {
          type: "given_name",
          label: "First Name",
          placeholder: "Name",
          required: true,
        },
        {
          type: "family_name",
          label: "Last Name",
          placeholder: "Name",
          required: true,
        },
        {
          type: "password",
          label: "Password",
          placeholder: "****",
          required: true,
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // Initiate SSO flow if sso query parameter is true
    if (to.query.sso === "true") {
      Auth.federatedSignIn({
        provider: process.env.VUE_APP_ASU_SAML_PROVIDER_NAME,
      });
    } else if (to.query.event) {
      next((vm) => {
        // The beforeRouteEnter guard does NOT have access to this
        // you can access the instance by passing a callback to next
        // @see https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
        vm.$store.commit("setDocusignEvent", to.query.event); // set docusign event
        // Clear envelope if event is cancel or decline
        // if (to.query.event != 'signing_complete') vm.$store.commit("setEnvelopeId", 'undefined');
      });
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(["signedIn"]),
    initial_auth_state() {
      return this.$route.query.signup === "true"
        ? AuthState.SignUp
        : AuthState.SignIn;
    },
    upl() {
      return process.env.VUE_APP_AWS_USER_POOL_LOCATION;
    },
    sso_enabled() {
      return (
        process.env.VUE_APP_AWS_USER_POOLS_DOMAIN &&
        process.env.VUE_APP_ASU_SAML_PROVIDER_NAME
      );
    },
  },
  methods: {
    async ionViewCanEnter() {
      try {
        await Auth.currentAuthenticatedUser();
        return true;
      } catch {
        return false;
      }
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    SSO() {
      Auth.federatedSignIn({
        provider: process.env.VUE_APP_ASU_SAML_PROVIDER_NAME,
      });
    },
  },
};
</script>

<style scoped lang="scss">
img {
  width: 100%;
  height: auto;
}
.federated-buttons {
  button {
    color: #fdc628;
    font-weight: bold;
    border: 1px solid #fdc628;
    border-radius: 8px;
    background: #000;
  }
  p {
    color: black;
  }
}
</style>
<style lang="scss">
:root {
  --amplify-background-color: transparent !important;
  --amplify-primary-color: #fdc628;
  --amplify-secondary-color: #fff;
  --background-color: #ffffff30;
  --amplify-secondary-contrast: #ffffff30;
  --background-color: red;
  --border-color: none;
  --label-color: #fff;
  --color: #fff;
  --amplify-primary-contrast: #000;
  --padding: 10px 30px !important;
  --font-family: kallisto-bold;
  --amplify-font-family: kallisto-bold;
  --amplify-components-fieldcontrol-border-radius: 8px;
  --amplify-components-button-border-radius: 8px;
}

amplify-sign-in,
amplify-sign-up {
  background-color: transparent;
}
.add_bg {
  background-image: linear-gradient(to bottom, #0000007d, #191919),
    url("../assets/images/FOREST_CONCEPT_Act.png");
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: cover;
}
.black-logo {
  position: fixed;
  top: 4%;
  left: 4%;
  width: 20% !important;
  min-width: 145px;
}
</style>