<template>
  <div
    class="modal fade"
    id="pod-check-in"
    data-backdrop="exampleModalLabel"
    data-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
    style="padding-left: 0px !important; padding-right: 0px !important"
  >
    <div
      class="modal-dialog my-0"
      style="
        max-width: 100%;
        height: 100vh;
        background-color: #151515;
        overflow: hidden;
      "
    >
      <div class="modal-content">
        <div class="modal-body p-0" style="height: 100vh">
          <div class="p-0">
            <div v-if="checkin_step == 1" class="row">
              <div class="col-8 d-none d-md-block p-0">
                <div class="wrapper">
                  <video
                    class="w-100 pod-check-in-video"
                    style="height: 101vh"
                    autoplay
                    loop
                    muted
                    playsinline="true"
                    disablePictureInPicture="true"
                  >
                    <source
                      src="
                        /videos/megaraffe-animation_optimized.mp4
                      "
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>

                <button
                  data-dismiss="modal"
                  style="border: none"
                  class="close-logo-image"
                  @click="closeCheckin()"
                >
                  <img
                    src="../assets/images/logo-left.png"
                    alt="close image"
                    class=""
                  />
                </button>
              </div>
              <div class="col-12 col-md-4 p-5">
                <p class="text-right mb-0 d-block d-md-none">
                  <button data-dismiss="modal">x</button>
                </p>
                <p
                  class="font-md-16 font-14 font-weight-bold brother-regular mb-1"
                  style="color: #d0d0d0"
                >
                  Welcome to Dreamscape Learn
                </p>
                <h1
                  class="font-weight-bold font-md-32 font-20 Highlighted mt-0 mb-4 kallisto-heavy"
                  style="filter: drop-shadow(0px 0px 2px #ffc627)"
                >
                  Check in here!
                </h1>
                <div class="qr-scanner" v-if="enableCamera">
                  <qrcode-stream
                    class="d-none d-xl-block"
                    @decode="onDecode"
                  ></qrcode-stream>
                  <qrcode-stream class="d-xl-none d-block" @decode="onDecode">
                    <img
                      src="../assets/images/scanner_lines.png"
                      alt="scanner_lines"
                      class="scanner_lines-image w-100"
                    />
                  </qrcode-stream>
                </div>
                <p class="font-md-16 font-14 mt-3 mb-2 pb-1 os-bold">
                  Scan your QR code below
                </p>
                <img
                  src="../assets/images/QR-code.png"
                  alt="qr-code"
                  class="qr-code-image"
                  style="max-width: 50%"
                />
                <div class="d-flex justify-content-center">
                  <lottie-player
                    autoplay
                    loop
                    mode="normal"
                    class="check-in-animation"
                    :src="check_in_arrow_json"
                    style="width: 100px; height: 100px"
                  ></lottie-player>
                </div>
              </div>
            </div>
            <div
              v-else-if="checkin_step == 2"
              class="checkin-screen text-left px-md-5 px-0"
              :style="{
                'background-image': `linear-gradient(90deg, #000 0%, rgba(21, 21, 21, 0.00) 100%),url(${checkinImage})`,
              }"
            >
              <h1
                class="font-weight-bold kallisto-heavy font-md-42 font-20 mb-12 px-3"
              >
                Check in complete!
              </h1>
              <p
                class="font-md-22 font-16 kallisto-medium font-weight-normal w-50 px-3 mb-2"
              >
                See your reservation details below
              </p>
              <div
                class="d-flex col-12 col-md-6"
                style="flex: 0 0 0 !important"
              >
                <div class="col-6 d-flex px-0" style="">
                  <img
                    src="../assets/icons/name_icon.png"
                    alt="name_icon"
                    class="group_icon-image"
                    height="55"
                  />
                  <div class="ml-2">
                    <p class="group-title mb-1 font-md-16 font-12">Name:</p>
                    <p class="group-value mb-0 font-md-18 font-14 os-bold">
                      {{ $store.state.ticket_details.ticket.first_name }}
                      {{ $store.state.ticket_details.ticket.last_name }}
                    </p>
                  </div>
                </div>
                <div class="col-6 d-flex px-0" style="">
                  <img
                    src="../assets/icons/pod_icon.png"
                    alt="pod_icon"
                    class="group_icon-image"
                    height="55"
                  />
                  <div class="ml-2">
                    <p class="group-title mb-1 font-md-16 font-12">Pod:</p>
                    <p class="group-value mb-0 font-md-18 font-14 os-bold">
                      {{
                        $store.state.ticket_details.ticket.ticketed_pod.slice(
                          $store.state.ticket_details.ticket.ticketed_pod
                            .length - 2
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="d-flex col-12 col-md-6"
                style="flex: 0 0 0 !important"
              >
                <div class="col-6 d-flex px-0" style="">
                  <img
                    src="../assets/icons/show_icon.png"
                    alt="show_icon"
                    class="group_icon-image"
                    height="55"
                  />
                  <div class="ml-2">
                    <p class="group-title mb-1 font-md-16 font-12">Show:</p>
                    <p class="group-value mb-0 font-md-18 font-14 os-bold">
                      {{ $store.state.ticket_details.title.shortname }}
                    </p>
                  </div>
                </div>
              </div>
              <p class="d-flex justify-content-center">
                <button
                  class="back-btn font-22 font-weight-bold os-bold"
                  @click="(checkin_step = 1), (enableCamera = true)"
                >
                  Finish
                </button>
              </p>
            </div>
            <div
              v-else-if="checkin_step == 3"
              class="oops-screen text-left px-5"
              :style="{
                'background-image': `linear-gradient(90deg, #000 0%, rgba(21, 21, 21, 0.00) 100%),url(${checkinImage})`,
              }"
            >
              <h1 class="font-md-42 font-20 mb-3 kallisto-heavy">Oops!</h1>
              <p class="font-md-24 font-16 w-50 kallisto-medium">
                {{ showErrorMsg.text }}
              </p>
              <p class="d-flex justify-content-center">
                <button
                  class="back-btn font-22 font-weight-bold os-bold"
                  @click="
                    (checkin_step = 1),
                      (enableCamera = true),
                      (scanStatus = true)
                  "
                >
                  Go back
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import check_in_arrow_animation from "../assets/icons/check_in_arrow_animation.json";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import { API } from "aws-amplify";
export default {
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  data() {
    return {
      checkin_step: 1,
      camera: "rear",
      noRearCamera: false,
      noFrontCamera: false,
      enableCamera: false,
      check_in_arrow_json: JSON.stringify(check_in_arrow_animation),
      showErrorMsg: {
        status: false,
        text: "",
      },
      scanStatus: true,
      checkinImage: require("../assets/images/checkin_image.png"),
    };
  },
  methods: {
    getActImg() {
      const vm = this;
      var count = 1;
      this.$store.state.scheduler_pods[0].pods.forEach((element) => {
        if (element.titles && vm.$store.state.ticket_details.ticket) {
          element.titles.forEach((title) => {
            if (
              count == 1 &&
              title.img_url &&
              vm.$store.state.ticket_details.ticket.ticketed_title != "" &&
              title.title_id ==
                vm.$store.state.ticket_details.ticket.ticketed_title
            ) {
              count++;
              console.log(title.img_url);
              vm.checkinImage = title.img_url;
              return toString(title.img_url);
            }
          });
        }
      });
    },
    closeCheckin() {
      this.$store.commit("POD_CHECKIN", false);
    },
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },
    async onDecode(decode) {
      if (
        decode != "" &&
        decode != null &&
        decode != undefined &&
        this.scanStatus
      ) {
        this.scanStatus = false;
        await API.get("asulink", `/customer/ticket/id/ASU00001/${decode}`)
          .then((response) => {
            this.$store.commit("SET_ORDER_DETAILS", response);
            this.checkinComplete();
          })
          .catch((e) => {
            this.checkin_step = 3;
            this.scanStatus = true;
            this.showErrorMsg.status = true;
            this.showErrorMsg.text = e.response.data.message;
          });
      }
    },
    async checkinComplete() {
      if (this.$store.state.ticket_details.ticket) {
        this.getActImg();
        let user_details = {
          body: {
            closed_captioning: false,
            language_code: "en", //this.$store.state.ticket_details.tickets.audio_lang_code
            ticket_id: this.$store.state.ticket_details.ticket.ticket_id,
            site_id: "ASU00001",
          },
        };
        await API.post("asulink", `/customer/checkin/complete`, user_details)
          .then(async (response) => {
            this.$store.commit("SET_STUDENT_CHECKIN", response);
            this.scanStatus = true;
            this.checkin_step = 2;
            this.enableCamera = false;
          })
          .catch((e) => {
            this.scanStatus = true;
            this.checkin_step = 3;
            this.enableCamera = false;
            this.showErrorMsg.status = true;
            this.showErrorMsg.text = e.response.data.message;
          });
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        console.error(error);
      }
    },
  },
  mounted() {
    let vm = this;
    $("#pod-check-in").on("hide.bs.modal", function () {
      vm.showErrorMsg.status = false;
      vm.showErrorMsg.text = "";
      vm.enableCamera = false;
      vm.checkin_step = 1;
    });
    $("#pod-check-in").on("shown.bs.modal", function () {
      vm.enableCamera = true;
      vm.scanStatus = true;
    });
  },
};
</script>

<style lang="scss">
.qr-code-image {
  width: 92px;
  height: 88px;
}
.check-in-animation {
  transform: rotate(180deg);
}
body.modal-open {
  overflow: hidden;
}
#pod-check-in {
  .Highlighted {
    color: #ffc627;
  }
  .close-logo-image {
    position: absolute;
    z-index: 1;
    left: 0%;
    bottom: 0%;
  }
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
    .close-logo-image {
      bottom: 8% !important;
    }
  }
  .qrcode-stream-wrapper {
    height: 315px !important;
  }
  .qrcode-stream-overlay {
    display: flex;
  }
  .qrcode-stream-camera {
    border-radius: 25px;
  }
  .pod-check-in-video {
    height: 100vh;
    object-fit: cover;
  }
  .wrapper {
    position: relative;
    width: 100%;
  }
  .wrapper::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 100%;
    content: "";
    background-image: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0) 13%,
      #151515 100%
    );
  }
  video {
    width: 100%;
    aspect-ratio: 4/3;
  }
  .oops-screen {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .checkin-screen {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .back-btn {
    background: #ffc627;
    color: #151515;
    font-weight: bold;
    position: absolute;
    bottom: 10%;
    border-radius: 10px;
    width: 169px;
    height: 50px;
  }
  .group-title {
    color: rgba(255, 255, 255, 0.7);
    padding-left: 12px;
    font-weight: bold;
  }
}
.font-42 {
  font-size: 42px;
}
.group-value {
  padding-left: 12px !important;
}
.scanner_lines-image {
  opacity: 0.5;
}
.qrcode-stream-overlay {
  padding: 32px;
}
</style>