<template>
  <div class="info">
    <userDetails />
    <div v-if="$store.state.role == 'student'"><student /></div>
    <div v-if="$store.state.role == 'pod operator'">
      <appointments /> <pod-check-in></pod-check-in>
    </div>
    <div v-if="$store.state.role == 'instructor'">
      <Calendar v-if="this.$store.state.viewFromMenu == 'Scheduler'"></Calendar>
      <appointments
        v-else-if="this.$store.state.viewFromMenu == 'Pod Schedule'"
      ></appointments>
      <InstructorDashboard v-else></InstructorDashboard>
    </div>
    <div v-if="$store.state.role == 'admin'">
      <Calendar v-if="this.$store.state.viewFromMenu == 'Scheduler'"></Calendar>
      <appointments
        v-else-if="this.$store.state.viewFromMenu == 'Pod Schedule'"
      ></appointments>
      <InstructorDashboard v-else></InstructorDashboard>
      <pod-check-in></pod-check-in>
    </div>
    <div class="container text-left" v-if="showApi">
      <div>
        <h2>Available Titles</h2>
        <pre>{{ titles }}</pre>
      </div>
      <div>
        <h2>Available Sites</h2>
        <pre>{{ sites }}</pre>
      </div>
      <div>
        <h2>Expirence Tickets</h2>
        <pre>{{ expirenceTIckets }}</pre>
      </div>
      <div>
        <h2>Avatars</h2>
        <pre>{{ avatars }}</pre>
      </div>
      <div>
        <h2>Remote Sessions</h2>
        <pre>{{ remoteSessions }}</pre>
      </div>
      <div>
        <h2>Languages</h2>
        <pre>{{ languages }}</pre>
      </div>
      <div>
        <h2>Schedules</h2>
        <pre>{{ schedules }}</pre>
      </div>
      <div>
        <h2>userData</h2>
        <pre>{{ userData }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import appointments from "@/components/Appointments.vue";
import userDetails from "@/components/userDetails.vue";
import podCheckIn from "@/components/podCheckIn.vue";
import student from "../views/Home.vue";
import { Auth, API } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import InstructorDashboard from "../components/instructor/InstructorDashboard";
import Calendar from "../components/instructor/Calendar";
import $ from "jquery";
export default {
  name: "LoggedInUser",
  data: () => {
    return {
      sessionInfo: {},
      userProfile: {},
      titles: [],
      sites: [],
      expirenceTIckets: [],
      avatars: [],
      remoteSessions: {},
      languages: [],
      schedules: [],
      userData: [],
      orders: [],
      showApi: false,
    };
  },
  components: {
    appointments,
    student,
    userDetails,
    InstructorDashboard,
    Calendar,
    podCheckIn,
  },
  methods: {
    pushTitles(titles) {
      // check and push to titles
      if (titles.length > 0) {
        titles.forEach((title) => {
          let getStatus = this.$store.state.titles.find(
            (status) => status.title_id === title.title_id
          );
          if (getStatus == undefined) {
            this.$store.state.titles.push(title);
          }
        });
      }
    },
  },
  created() {
    // authentication state managament
    // @see https://dev.to/aws/build-your-first-full-stack-serverless-app-with-vue-and-aws-amplify-13p8
    onAuthUIStateChange(async (state, user) => {
      switch (state) {
        case AuthState.SignedIn:
          this.$store.state.loader = true;
          // @see https://docs.amplify.aws/lib/auth/manageusers/q/platform/js
          this.sessionInfo = await Auth.currentSession();
          this.userProfile = await API.get("asulink", "/account/profile");
          this.$store.dispatch("setProfile");
          if (this.userProfile.role == "pod operator") {
            this.$store.state.role = "pod operator";
            this.$store.state.viewFromMenu = "Pod Schedule";
            if (
              window.location.search.includes("event=signing_complete") &&
              window.location.search.includes("signing_service=ADOBE_SIGN") &&
              this.$store.state.agreementId
            ) {
              let agreement = {
                body: {
                  signing_service: "ADOBE_SIGN",
                  agreementId: this.$store.state.agreementId,
                },
              };
              await API.put(
                "proxy",
                `/${this.$store.state.selected_site_id}/waiver/${this.$store.state.addedUser.customer_id}`,
                agreement
              )
                .then(async (res) => {
                  let user_details = {
                    body: {
                      avatar_id: this.$store.state.selected_avatar_id,
                      site_id: this.$store.state.selected_site_id,
                      closed_captioning: false,
                      customer_id: this.$store.state.addedUser.customer_id,
                      language_code: "en",
                      session_id: this.$store.state.selected_session,
                      ada: false,
                    },
                  };
                  try {
                    window.history.replaceState(
                      null,
                      null,
                      window.location.pathname
                    );
                    await this.$store
                      .dispatch("confirmUserDetails", user_details)
                      .then(async () => {});
                  } catch (e) {
                    this.loader = false;
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.$store.commit("RESET_USER_DETAILS", undefined);
                  window.history.replaceState(
                    null,
                    null,
                    window.location.pathname
                  );
                });
            } else if (
              this.$store.state.envelopeId &&
              window.location.search.includes("event=signing_complete")
            ) {
              this.$store.state.viewFromMenu = "Pod Schedule";
              let envel = {
                body: {
                  signing_service: "DOCUSIGN",
                  envelopeId: this.$store.state.envelopeId,
                },
              };
              await API.put(
                "proxy",
                `/${this.$store.state.selected_site_id}/waiver/${this.$store.state.addedUser.customer_id}`,
                envel
              ).then(async (res) => {
                let user_details = {
                  body: {
                    avatar_id: this.$store.state.selected_avatar_id,
                    site_id: this.$store.state.selected_site_id,
                    closed_captioning: false,
                    customer_id: this.$store.state.addedUser.customer_id,
                    // customer_id: this.$store.state.addedUser.customer_id,
                    language_code: "en",
                    session_id: this.$store.state.selected_session,
                    ada: false,
                  },
                };
                try {
                  await this.$store
                    .dispatch("confirmUserDetails", user_details)
                    .then(async () => {});
                } catch (e) {
                  this.loader = false;
                }
              });
            }
          } else if (this.userProfile.role == "student") {
            this.$store.state.role = "student";
            this.$store.state.component = "landing";
            this.$store.state.view = "alien";
          } else if (this.userProfile.role == "admin") {
            this.$store.state.role = "admin";
            this.$store.state.loader = false;
            this.$store.state.viewFromMenu = "Dashboard";
            if (
              window.location.search.includes("event=signing_complete") &&
              window.location.search.includes("signing_service=ADOBE_SIGN") &&
              this.$store.state.agreementId
            ) {
              this.$store.state.viewFromMenu = "Pod Schedule";
              let agreement = {
                body: {
                  signing_service: "ADOBE_SIGN",
                  agreementId: this.$store.state.agreementId,
                },
              };
              await API.put(
                "proxy",
                `/${this.$store.state.selected_site_id}/waiver/${this.$store.state.addedUser.customer_id}`,
                agreement
              ).then(async (res) => {
                let user_details = {
                  body: {
                    avatar_id: this.$store.state.selected_avatar_id,
                    site_id: this.$store.state.selected_site_id,
                    closed_captioning: false,
                    customer_id: this.$store.state.addedUser.customer_id,
                    // customer_id: this.$store.state.addedUser.customer_id,
                    language_code: "en",
                    session_id: this.$store.state.selected_session,
                    ada: false,
                  },
                };
                try {
                  window.history.replaceState(
                    null,
                    null,
                    window.location.pathname
                  );
                  await this.$store
                    .dispatch("confirmUserDetails", user_details)
                    .then(async () => {});
                } catch (e) {
                  window.history.replaceState(
                    null,
                    null,
                    window.location.pathname
                  );
                  this.loader = false;
                }
              });
            } else if (
              this.$store.state.envelopeId &&
              window.location.search.includes("event=signing_complete")
            ) {
              this.$store.state.viewFromMenu = "Pod Schedule";
              let envel = {
                body: {
                  signing_service: "DOCUSIGN",
                  envelopeId: this.$store.state.envelopeId,
                },
              };
              await API.put(
                "proxy",
                `/${this.$store.state.selected_site_id}/waiver/${this.$store.state.addedUser.customer_id}`,
                envel
              ).then(async (res) => {
                let user_details = {
                  body: {
                    avatar_id: this.$store.state.selected_avatar_id,
                    site_id: this.$store.state.selected_site_id,
                    closed_captioning: false,
                    customer_id: this.$store.state.addedUser.customer_id,
                    language_code: "en",
                    session_id: this.$store.state.selected_session,
                    ada: false,
                  },
                };
                try {
                  await this.$store
                    .dispatch("confirmUserDetails", user_details)
                    .then(async () => {});
                } catch (e) {
                  this.loader = false;
                }
              });
            }
            this.$store.dispatch("setAvatarsGlobal");
            this.$store.dispatch("setTitles");
            let myData = {
              selected_site_id: this.$store.state.selected_site_id,
              podSessionDate: this.$store.state.podSessionDate,
            };
            this.$store.dispatch("setSchedulerSessions", myData);
          } else if (this.userProfile.role == "instructor") {
            this.$store.state.role = "instructor";
            this.$store.state.loader = false;
          }

          // If student
          if (this.userProfile.role == "student") {
            if (window.location.search == "?event=decline") {
              $("#ErrorSigningDoc").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
            this.$store.dispatch("getOutstandingOrders");

            // Call DSL API to load accout profile (store action)
            this.$store.dispatch("loadDslAccountProfile");
            // Load waivers
            this.$store.dispatch("loadWaivers").then(async () => {
              this.$store.state.loader = false;
              if (
                window.location.search.includes("signing_service=ADOBE_SIGN") &&
                window.location.search.includes("event=signing_complete") &&
                this.$store.state.agreementId
              ) {
                this.$store.state.component = "landing";
                this.$store.state.view = "pods";
                await API.get(
                  "proxy",
                  `/${this.$store.state.selected_site_id}/waiver/agreement/${this.$store.state.agreementId}`
                ).then(async (res) => {
                  let agID = res.id;
                  let myAgreement = {
                    body: {
                      signing_service: "ADOBE_SIGN",
                      agreementId: agID,
                    },
                  };
                  await API.put(
                    "proxy",
                    `/${this.$store.state.selected_site_id}/waiver`,
                    myAgreement
                  ).then(() => {
                    this.$store.commit("setAgreementId", null);
                  });
                });
              } else if (
                this.$store.state.envelopeId &&
                window.location.search.includes("event=signing_complete")
              ) {
                this.$store.state.component = "landing";
                this.$store.state.view = "pods";
                await API.get(
                  "proxy",
                  `/${this.$store.state.selected_site_id}/waiver/envelope/${this.$store.state.envelopeId}`
                ).then(async (res) => {
                  // redirect to URL and then redirecting back PUT envelope
                  let elID = res.envelopeId;
                  let myEnvelop = {
                    body: {
                      signing_service: "DOCUSIGN",
                      envelopeId: elID,
                    },
                  };
                  await API.put(
                    "proxy",
                    `/${this.$store.state.selected_site_id}/waiver`,
                    myEnvelop
                  ).then(() => {
                    this.$store.commit("setEnvelopeId", null);
                  });
                });
              } else if (this.$store.state.waivers.length > 0) {
                this.$router.push({ name: "Dashboard" });
              }
            });
            this.$store.dispatch("setAvatarsGlobal");

            // 2:sessions,
            this.$store.dispatch("setGeneralSessions");

            // 3:sites,
            this.$store.dispatch("setSites");

            // 4:expierences based on site( tree ),
            this.$store.dispatch(
              "setStudentExpierences",
              this.$store.state.selected_site_id
            );

            this.$store.state.scheduler_pods = await API.get(
              "proxy",
              `/${this.$store.state.selected_site_id}/dsl/common/title-mapping`
            );
            if (
              this.$store.state.scheduler_pods.length > 0 &&
              this.$store.state.scheduler_pods[0].pods.length > 0
            ) {
              this.$store.state.scheduler_pods[0].pods.forEach((element) => {
                if (element.titles) {
                  this.pushTitles(element.titles);
                }
              });
            }

            // 5:Click on any title get available expirences : goto treeCourse.vue
            // 6: post on ouder details : goto confirmSteps.vue
          } else if (this.userProfile.role == "pod operator") {
            // 1. get titles common
            if (
              this.$store.state.envelopeId &&
              window.location.search.includes("event=signing_complete")
            ) {
              let envel = {
                body: {
                  envelopeId: this.$store.state.envelopeId,
                },
              };
              await API.put(
                "proxy",
                `/${this.$store.state.selected_site_id}/waiver/${this.$store.state.addedUser.customer_id}`,
                envel
              ).then(async (res) => {
                let user_details = {
                  body: {
                    avatar_id: this.$store.state.selected_avatar_id,
                    site_id: this.$store.state.selected_site_id,
                    closed_captioning: false,
                    customer_id: this.$store.state.addedUser.customer_id,
                    // customer_id: this.$store.state.addedUser.customer_id,
                    language_code: "en",
                    session_id: this.$store.state.selected_session,
                    ada: false,
                  },
                };
                try {
                  await this.$store
                    .dispatch("confirmUserDetails", user_details)
                    .then(async () => {});
                } catch (e) {
                  console.log(e);
                  this.loader = false;
                }
              });
            }
            this.$store.dispatch("setAvatarsGlobal");
            this.$store.state.scheduler_pods = await API.get(
              "proxy",
              `/${this.$store.state.selected_site_id}/dsl/common/title-mapping`
            );
            this.$store.dispatch("setTitles");
            // 2. get group of sessions
            let myData = {
              selected_site_id: this.$store.state.selected_site_id,
              podSessionDate: this.$store.state.podSessionDate,
            };
            this.$store.dispatch("setSchedulerSessions", myData);
          }
          // other than student
          else {
            const schedules = await API.get(
              "proxy",
              `/${this.$store.state.selected_site_id}/dsl/common/title-mapping`
            );
            var myObject = {};
            schedules.title_mapping[0].pods.forEach((pod) => {
              myObject[pod.pod_number] = {
                gear_id: pod.gearups[0].gearup_id,
                gearups: pod.gearups,
                titles: pod.titles,
                capacity: pod.gearups[0].max_users,
              };
            });

            this.$store.state.scheduler_pods = myObject;
            if (
              schedules.title_mapping?.length > 0 &&
              schedules.title_mapping[0].pods?.length > 0 &&
              schedules.title_mapping[0].pods[0].gearups?.length > 0
            ) {
              this.$store.state.calendarSelectedGearup =
                schedules.title_mapping[0].pods[0].gearups[0];
            }
            this.$store.state.allCourses = await API.get(
              "proxy",
              `/${this.$store.state.selected_site_id}/dsl/common/asu/courses`
            );

            this.$store.dispatch("setAllSites");
            this.titles = await API.get("asulink", "/common/titles");
            this.$store.state.expirenceTIckets = await API.get(
              "asulink",
              `/tickets/experiences/${this.$store.state.selected_site_id}`
            );
            this.$store.state.expierenceAvailabilities = await API.get(
              "asulink",
              `/tickets/experiences/availability/${this.$store.state.selected_site_id}/${this.$store.state.titleid}`
            );
            this.$store.state.avatars = await API.get(
              "asulink",
              `/common/avatars/${this.$store.state.titleid}`
            );
            this.$store.state.sessions = await API.get(
              "asulink",
              "/account/sessions"
            );
          }
          break;

        case AuthState.SignedOut:
          this.sessionInfo == {};
          this.userProfile == {};
          break;
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
pre {
  color: white;
}
</style>
