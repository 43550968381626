import { render, staticRenderFns } from "./InstructorDashboard.vue?vue&type=template&id=1071d102&scoped=true&"
import script from "./InstructorDashboard.vue?vue&type=script&lang=js&"
export * from "./InstructorDashboard.vue?vue&type=script&lang=js&"
import style0 from "./InstructorDashboard.vue?vue&type=style&index=0&id=1071d102&prod&lang=scss&"
import style1 from "./InstructorDashboard.vue?vue&type=style&index=1&id=1071d102&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1071d102",
  null
  
)

export default component.exports